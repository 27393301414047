import { ref } from "vue";
import { createSharedComposable } from "@vueuse/core";

export const useSafeProductSearchSuggest = createSharedComposable(() => {
  if (typeof useProductSearchSuggest !== "function") {
    return {
      searchTerm: ref(""),
      search: () => "",
      getProducts: ref(""),
      loading: ref(""),
      getTotal: ref(""),
    };
  }

  const { searchTerm, search, getProducts, loading, getTotal } =
    useProductSearchSuggest();

  return {
    searchTerm,
    search,
    getProducts,
    loading,
    getTotal,
  };
});
