<template>
  <div
    v-if="!loading || getProducts"
    class="absolute top-[calc(100%-1px)] px-4 md:px-6 3xl:px-12 py-10 bg-white border-t border-t-light-grey flex items-start justify-between z-10 w-full"
  >
    <div
      v-if="getProducts?.length"
      class="bg-white relative grid gap-1 grid-cols-2 md:grid-cols-4 md:grid-rows-1 w-full"
    >
      <div
        v-for="product in getProducts"
        :key="product.id"
        class="md:inline-block"
        @click="onProductClick"
      >
        <ProductCard class="h-full" :product="product" size="regular" />
      </div>

      <div class="flex">
        <p
          class="ml-auto mt-auto text-m-xl md:text-xl text-center cursor-pointer underline"
          @click="onSeeAll"
        >
          {{
            $t("search.allResultsText", {
              amount: getTotal,
            })
          }}
        </p>
      </div>
    </div>

    <div v-else>
      <h1 class="mb-8 mt-8 md:mt-0 text-m-3xl md:text-3xl text-center">
        {{ $t("search.noResults") }}
      </h1>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch, onMounted } from "vue";
import ProductCard from "@/components/product/ProductCard.vue";
import { useSafeProductSearchSuggest } from "@/composables/useSafeProductSearchSuggest";
import { customFieldKeyResolver } from "@/constants/customFieldKeyResolver";
import { useDebounceFn } from "@vueuse/core";
import associations from "@/constants/associations";

const props = defineProps<{
  searchValue: string;
}>();

const emits = defineEmits(["mouseleave", "seeAll", "seeProduct"]);

const { searchTerm, search, getProducts, loading, getTotal } =
  useSafeProductSearchSuggest();

const onSeeAll = () => {
  emits("seeAll", props.searchValue);
};

const handleSearch = () => {
  searchTerm.value = props.searchValue;
  search({
    limit: 3,
    filter: [
      {
        type: "multi",
        operator: "and",
        queries: [
          {
            type: "equals",
            field: `customFields.${customFieldKeyResolver["PRODUCT_NOT_BUYABLE_MIGRATION"]}`,
            value: false,
          },
        ],
      },
    ],
    associations: {
      ...associations.product.attachSecondMedia,
    },
  });
};

const onProductClick = () => {
  emits("seeProduct");
}

const debounceSearch = useDebounceFn(handleSearch, 400);

watch(
  () => props.searchValue,
  () => {
    if (props.searchValue.length >= 3) {
      debounceSearch();
    }
  },
);

onMounted(() => {
  handleSearch();
});
</script>
